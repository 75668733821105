<template>
  <v-container v-if="$store.state.user">

    <!-- Page Title-->
    <v-row class="text-center mt-10">
        <v-col class="mb-2">
            <h3 class="display-2 font-weight-bold mb-3">Add Payment</h3>
        </v-col>
    </v-row>

    <!-- Start of form -->
    <v-row class="justify-center">
        <v-col class="mt-16" cols="4">
            <v-form ref="form" v-model="valid" lazy-validation>

                <!-- Payment type dropdown -->
                <v-select :items="typeNames" label="Payment Type" solo></v-select>

                <!-- Amount input field -->
                <v-text-field v-model="amount" label="Amount" prepend-icon="mdi-currency-usd"></v-text-field>

                <!-- Date picker component -->
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
                    transition="scale-transition" offset-y min-width="auto">

                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="date"
                            label="Date"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>

                    <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>

                    <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                    </v-date-picker>
                </v-menu>

                <!-- Notes input field -->
                <v-textarea label="Notes" v-model="notes" prepend-icon="mdi-message-bulleted"></v-textarea>

                <!-- Submit Button -->
                <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Submit</v-btn>

                <!-- Clear Button (remove all user inputs) -->
                <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>
            </v-form>
        </v-col> 
    </v-row>

  </v-container>
</template>

<script>
import PeopleService from '../../services/PeopleService';

    export default {
        name: 'addPayment',

        data: () => ({
            valid: true,
            name: '',
            amount: null,
            notes: '',

            // Custom name rules to ensure validity of user input
            nameRules: [
                v => !!v || 'Name is required',
                v => (v && v.length <= 30) || 'Name must be less than 30 characters',
            ],
            select: null,

            // *TODO* : Pull assessment types from database instead of hardcoded array below
            assessmentTypes: [],

            // Hard-coded array for assessment type dropdown within form.
            typeNames: ["Monthly Payment"],
            payment_type_id: 1,

            // Variables below used for the date picker Vuetify component
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
        }),

        methods: {
            validate () {
                this.$refs.form.validate()

                PeopleService.sendAddPayment(
                    {
                        payment_type_id: this.payment_type_id,
                        date: this.date,
                        note: this.notes,
                        amount: this.amount,
                        person_id: this.$store.state.personID,
                    }
                )

                // Move back to the previous page
                this.$router.push({ path: `/associations/peopleDetails/${this.$store.state.personID}`})
            },

            reset () {
                this.$refs.form.reset()
            }
        }
    }
</script>